import React from 'react';
import Api from '../../services/Api/Api';
import ManifestEditForm from '../../views/Manifest/ManifestEditForm';

import Modal from "../../components/Modal/Modal";
import {Route, withRouter} from "react-router-dom";
import {DepartmentsResource,ExternalsResource,ShipsResource,OfficesResource} from '../../services/Firestore/Resources';
import {useLoading} from "../../services/LoadingContext/LoadingContext";
import {useErrorHandler} from "../../services/Utils/useErrorHandler";
import {Manifest} from "../../views/Manifest/Manifest";
const ManifestPage = ({history,match,manifest}) => {

  const errorHandler = useErrorHandler();

  const handleEdit = useLoading(async (data) => {
    try {
      await Api.UpdateManifest(data);
      history.push("/manifests/" + data.id);
    } catch (e) {
      await errorHandler(e)
    }
  });

  const handleAddItem = useLoading(async ({manifestId,...item}) => {
    try {
      await Api.AddManifestItem({manifestId, ...item});
    } catch (e) {
      await errorHandler(e)
    }
  });

  const handleRemoveItem = useLoading(async ({manifestId,id}) => {
    try {
      await Api.RemoveManifestItem({manifestId, id});
    } catch (e) {
      await errorHandler(e)
    }
  });

  const handleUpdateItem = useLoading(async ({manifestId,...item}) => {
    try {
      await Api.UpdateManifestItem({manifestId, ...item});
    } catch (e) {
      await errorHandler(e)
    }
  });

  const handleSend = useLoading(async ({id}) => {
    try {
      await Api.SendManifest({id});
    } catch (e) {
      await errorHandler(e)
    }
  });

  const handleReceive = useLoading(async ({id}) => {
    try {
      await Api.ReceiveManifest({id});
    } catch (e) {
      await errorHandler(e)
    }
  });

  const returnToManifest = () => {
    history.push(match.url);
};

  if(!manifest) return("Manifest not found");

  return (
    <DepartmentsResource>{departments =>
      <ExternalsResource>{externals =>
        <ShipsResource>{ships =>
          <OfficesResource>{offices =>
            <>
                <Manifest
                  manifestId={manifest.id}
                  onAddItem={handleAddItem}
                  onRemoveItem={handleRemoveItem}
                  onUpdateItem={handleUpdateItem}
                  onSend={handleSend}
                  onReceive={handleReceive}
                />
              <Route path="/manifests/:id/edit" render={() =>
                <Modal open={true} onClose={returnToManifest}>
                  <ManifestEditForm
                    {...{manifest}}
                    onUpdate={handleEdit}
                    ships={ships}
                    offices={offices}
                    departments={departments}
                    externals={externals}
                  />
                </Modal>
              } />
            </>
          }</OfficesResource>
        }</ShipsResource>
      }</ExternalsResource>
    }</DepartmentsResource>

  )
};

export default withRouter(ManifestPage);