import { useState,useEffect } from 'react';

export const useLocalStorage = (key, initialValue) => {
    const [state, setState] = useState(initialValue);

    useEffect(() => {
        const item = window.localStorage.getItem(key);
        if (!item){
            return;
        }
        try {
            setState(JSON.parse(item))
        }catch(err){
            setState(undefined);
        }
    }, [key]);


    const setValue = value => {
        setState(value);
        window.localStorage.setItem(key, JSON.stringify(value));
    };

    return [state, setValue];
};