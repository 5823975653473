import React,{useState,useEffect} from "react"
import * as firebase from "firebase";
import Observable from "../Observable/Observable";
import {setLoading} from "../LoadingContext/LoadingContext";
import {User} from "../AuthContext/AuthContext";

export class FirestoreQuery extends Observable {
    static cache = [];

    data;
    dataReady;
    query;
    queryUnsubscribe;
    teardownTimeout;

    constructor(query) {
        super();
        if (!query instanceof firebase.firestore.Query) {
            throw Error("invalid firestore query");
        }
        this.query = query;
        if(!FirestoreQuery.cache.find(v=>v.query.constructor === query.constructor && v.query.isEqual(query))){
            FirestoreQuery.cache.push(this);
        }
    }

    static get(query) {
        if (!query instanceof firebase.firestore.Query) {
            throw Error("invalid firestore query");
        }

        let fq = FirestoreQuery.cache.find(v=>v.query.constructor === query.constructor && v.query.isEqual(query));
        if(!fq){
            fq = new FirestoreQuery(query);
        }
        return fq
    }

    init(){
        if (!this.queryUnsubscribe) {
            this.queryUnsubscribe = this.query.onSnapshot(snapshot => {
                this.dataReady = true;
                this.read(snapshot, this.notify.bind(this))
            }, err => {
                throw err
            });
        }
    }

    read(snapshot,f){
        if(snapshot instanceof firebase.firestore.QuerySnapshot) {
            if (!this.data){
                this.data = Resource.from([]);
            }
            this.data.splice(0,this.data.length);
            this.data.push(...snapshot.docs.map(doc => ({...doc.data(), id: doc.id})))
        }else if(snapshot instanceof firebase.firestore.DocumentSnapshot){
            this.data = {...snapshot.data(), id: snapshot.id}
        }else {
            throw Error("unknown snapshot type");
        }
        f(this.data);
    }

    subscribe(f){
        clearTimeout(this.teardownTimeout);
        this.init();
        if(this.dataReady) {
            this.query.get({source: 'cache'}).then(snapshot => {
                this.read(snapshot, f)
            });
        }
        return super.subscribe(f);
    }

    subscribeOnce(f) {
        return super.subscribeOnce(f);
    }

    unsubscribe(f) {
        super.unsubscribe(f);
        if(this.observers.length === 0){
            clearTimeout(this.teardownTimeout);
            this.teardownTimeout = setTimeout(this.teardown.bind(this),60000)
        }
    }

    teardown(){
        if (this.queryUnsubscribe) {
            this.queryUnsubscribe();
            this.queryUnsubscribe = undefined;
        }
        this.dataReady = false;
    }

    isEqual(other){
       return this.constructor === other.constructor &&  this.query.isEqual(other.query)
    }
}

export class FirestoreResource extends React.Component {
    constructor(props) {
        super(props);
        this.state = {data:null};

        if (!(this.props.query instanceof FirestoreQuery)) {
            throw Error("expected FirestoreQuery instance");
        }
    }

    fetchData(query){
        const load$ = query.subscribeOnce(setLoading());
        const data$ = query.subscribe(data=>{
            this.setState({data})
        });
        this.unsubscribe = ()=>{
            load$();
            data$();
        }
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.query.isEqual(nextProps.query)){
            return;
        }

        const {query} = nextProps;
        this.unsubscribe();
        this.fetchData(query);
    }

    componentDidMount() {
        const {query} = this.props;
        this.fetchData(query)
    }

    componentWillUnmount(){
       this.unsubscribe();
    }

    render() {
        const {children} = this.props;
        const {data} = this.state;
        if (!data){
            return <></>
        }
        return children(data)
    }
}


export class Resource extends Array{

    constructor(...args) {
        super(...args);
        if(!args) return;

        const index = {};
        let touched = false;

        this.indexAdd = (...items) => {
            touched = true;
            for(let i = items.length-1; i >= 0; i--){
                index[items[i].id] = items[i];
            }
        };

        this.indexRemove = (...items) => {
            for(let i = items.length-1; i >= 0; i--){
                delete(index,items[i].id);
            }
        };

        this.get = (id)=>{
            if(this.length > 0 && !touched){
                this.indexAdd(...this)
            }
            return index[id];
        };

        this.hash = ()=>{
            return btoa(JSON.stringify(Object.keys(index)))
        };

        if (args.length === 1 && typeof args[0] === "number") return;
        for(let i = args.length-1; i >= 0; i--){
            this.indexAdd(args[i])
        }
    }

    push(...items) {
        const v = super.push(...items);
        this.indexAdd(...items);
        return v;
    }

    splice(start, deleteCount) {
        const v = super.splice(start, deleteCount);
        this.indexRemove(...v);
        return v;
    }

    pop() {
        const v = super.pop();
        this.indexRemove(v);
        return v;
    }

    shift() {
        const v = super.shift();
        this.indexRemove(v);
        return v;
    }

    slice(start, end) {
        return new this.constructor(...super.slice(start, end));
    }

    unshift(...items) {
        const v = super.unshift(...items);
        this.indexAdd(...items);
        return v
    }

    fill(value, start, end) {
        this.index(value);
        return super.fill(value, start, end);
    }
}

export const useResource = (query,...args)=>{
    const [data, setData] = useState(null);
    if (query instanceof Function){
        query = query(...args);
    }
    if (!query instanceof FirestoreQuery) {
        throw Error("expected FirestoreQuery instance");
    }

    useEffect(() => {
        const load$ = query.subscribeOnce(setLoading());
        const data$ = query.subscribe((data)=>{
            setData(data)
        });
        return ()=>{
            load$();
            data$();
        }
        // eslint-disable-next-line
    }, [query,...args]);

    return data
};

export const FilesCollection = ()=> FirestoreQuery.get(firebase.firestore().collection("files"));
export const TasksCollection = ()=> FirestoreQuery.get(firebase.firestore().collection("tasks"));
export const AssetsCollection = ()=> FirestoreQuery.get(firebase.firestore().collection("assets"));
export const StoragesCollection = ()=> FirestoreQuery.get(firebase.firestore().collection("storages"));
export const ProductsCollection = ()=> FirestoreQuery.get(firebase.firestore().collection("products"));
export const UsersCollection = ()=> FirestoreQuery.get(firebase.firestore().collection("users"));
export const ManifestsCollection = ()=> FirestoreQuery.get(firebase.firestore().collection("manifests"));
export const ShipsCollection = ()=> FirestoreQuery.get(firebase.firestore().collection("ships"));
export const OfficesCollection = ()=> FirestoreQuery.get(firebase.firestore().collection("offices"));
export const DepartmentsCollection = ()=> FirestoreQuery.get(firebase.firestore().collection("departments"));
export const ExternalsCollection = ()=> FirestoreQuery.get(firebase.firestore().collection("externals"));

export const ProductDocument = id => FirestoreQuery.get(firebase.firestore().doc("products/"+id));
export const UserDocument = id => FirestoreQuery.get(firebase.firestore().doc("users/"+id));
export const AssetDocument = id => FirestoreQuery.get(firebase.firestore().doc("assets/"+id));
export const StorageDocument = id => FirestoreQuery.get(firebase.firestore().doc("storages/"+id));
export const ManifestDocument = id => FirestoreQuery.get(firebase.firestore().doc("manifests/"+id));

export const FilesResource = ({children})=> <FirestoreResource query={FilesCollection()}>{children}</FirestoreResource>;
export const TasksResource = ({children})=> <FirestoreResource query={TasksCollection()}>{children}</FirestoreResource>;
export const AssetsResource = ({children})=> <FirestoreResource query={AssetsCollection()}>{children}</FirestoreResource>;
export const ProductsResource = ({children})=> <FirestoreResource query={ProductsCollection()}>{children}</FirestoreResource>;
export const ManifestsResource =  ({children})=> <FirestoreResource query={ManifestsCollection()}>{children}</FirestoreResource>;
export const ShipsResource = ({children})=> <FirestoreResource query={ShipsCollection()}>{children}</FirestoreResource>;
export const OfficesResource = ({children})=> <FirestoreResource query={OfficesCollection()}>{children}</FirestoreResource>;
export const DepartmentsResource = ({children})=> <FirestoreResource query={DepartmentsCollection()}>{children}</FirestoreResource>;
export const ExternalsResource = ({children})=> <FirestoreResource query={ExternalsCollection()}>{children}</FirestoreResource>;
export const UsersResource = ({children,decorate = true})=> {
    return <FirestoreResource query={UsersCollection()}>{users =>
        children(Resource.from(decorate ? users.map(v=>new User(v)) : users))
    }</FirestoreResource>;
};
export const StoragesResource = ({children,decorate = true})=> <FirestoreResource query={StoragesCollection()}>{storages=>{
    if(!storages || !decorate){
        return children(storages);
    }
    return children(storages.map(storage=>{
        return {...storage,lineageNames:storage.lineage.map((v)=>storages.get(v).name)}
    }))
}}</FirestoreResource>;

export const ProductResource = ({id,children})=> <FirestoreResource query={ProductDocument(id)}>{children}</FirestoreResource>;
export const UserResource = ({id,children})=> <FirestoreResource query={UserDocument(id)}>{children}</FirestoreResource>;
export const AssetResource = ({id,children})=> <FirestoreResource query={AssetDocument(id)}>{children}</FirestoreResource>;
export const StorageResource = ({id,children})=> <FirestoreResource query={StorageDocument(id)}>{children}</FirestoreResource>;
export const ManifestResource = ({id,children})=> <FirestoreResource query={ManifestDocument(id)}>{children}</FirestoreResource>;

