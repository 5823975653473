import React,{useState} from 'react';
import {Link,Route,Switch,withRouter} from "react-router-dom";
import {ManifestsResource, DepartmentsResource,ExternalsResource,ShipsResource,OfficesResource} from "../../services/Firestore/Resources";
import ManifestList from "../../views/Manifest/ManifestList";
import ManifestPage from "./ManifestPage";
import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";
import Paper from "../../components/Paper/Paper";
import Input from "../../components/Input/Input";
import Modal from "../../components/Modal/Modal";
import withStyles from "react-jss";
import {AddressableConsumer} from "../../services/Context/Addressable";
import ManifestAddForm from '../../views/Manifest/ManifestAddForm';
import AuthContext from "../../services/AuthContext/AuthContext";


const styles = {
    listContainer: {
        display: "grid",
        gridTemplateColumns: "auto auto",
        gridTemplateRows: "auto auto",
        gridTemplateAreas: '"add search" "list list"',
    },
    add: {
        gridArea: 'add',
        justifySelf: 'self-start',
        marginTop: 10,
        marginLeft: 10,
    },
    search: {
        gridArea: 'search',
        justifySelf: 'self-end',
        marginTop: 10,
        marginLeft: 10,
        display: "grid",
        gridTemplateColumns: "auto auto",
    },
    searchInput: {
        margin: 0,
    },
    list: {
        gridArea: 'list',
    },
    columnFilter: {
        cursor: 'pointer',
        margin: 10,
    }
};

const ManifestListPage = ({history,classes}) => {

    const [filter,setFilter] = useState("");
    const [columnFilter,setColumnFilter] = useState(false);

    const handleFilterChange = (e) => {
        setFilter(e.target.value)
    };

    const handleAdd = (manifestId)=>{
        history.push("/manifests/"+manifestId);
    };

    const handleAbort = () => {
        history.push("/manifests");
    };

    return (
        <ManifestsResource>{manifests => manifests &&
            <AddressableConsumer>{addressables => addressables &&
                <Switch>
                    <Route exact path="/manifests(|/add)">
                        <Route path="/manifests/add">
                            <Modal open={true} onClose={handleAbort}>
                                <AuthContext.Consumer>{currentUser =>
                                    <DepartmentsResource>{departments =>
                                        <ExternalsResource>{externals =>
                                            <ShipsResource>{ships =>
                                                <OfficesResource>{offices =>
                                                    <ManifestAddForm
                                                        onSubmit={handleAdd}
                                                        ships={ships}
                                                        offices={offices}
                                                        departments={departments}
                                                        externals={externals}
                                                        currentUser={currentUser}
                                                    />
                                                }</OfficesResource>
                                            }</ShipsResource>
                                        }</ExternalsResource>
                                    }</DepartmentsResource>
                                }</AuthContext.Consumer>
                            </Modal>
                        </Route>
                        <Paper className={classes.listContainer}>
                            <Link to="/manifests/add" className={classes.add}>
                                <Button circle title="add">
                                    <Icon>add</Icon>
                                </Button>
                            </Link>
                    <div className={classes.search}>
                        <Input className={classes.searchInput} placeholder={"Search all manifests"} onChange={handleFilterChange} />
                        <div>{
                            !columnFilter ?
                                <Icon className={classes.columnFilter} title="Show custom filtering" onClick={() => setColumnFilter(true)}>search</Icon> :
                                <Icon className={classes.columnFilter} title="Hide custom filtering" onClick={() => setColumnFilter(false)}>search_off</Icon>
                        }
                        </div>
                    </div>

                    <ManifestList filter={filter} className={classes.list} {...{manifests,addressables,columnFilter}} />
                        </Paper>
                    </Route>
                    <Route path="/manifests/:id" render={({match}) =>
                        <ManifestPage manifest={manifests.find(a => a.id === match.params.id)} />
                    } />
                </Switch>
            }</AddressableConsumer>
        }</ManifestsResource>
    );
};

export default withRouter(withStyles(styles)(ManifestListPage));
