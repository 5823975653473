import React from 'react';
import {AssetLink} from "../../components/Link/AssetLink/AssetLink";
import {StorageLink} from "../../components/Link/StorageLink/StorageLink";
import {ManifestLink} from "../../components/Link/ManifestLink/ManifestLink";
import {DepartmentLink} from "../../components/Link/DepartmentLink/DepartmentLink";
import AddressableTitle from "../../components/Title/AddressableTitle/AddressableTitle";
import {ProductLink} from '../../components/Link/ProductLink/ProductLink';
import {UserLink} from '../../components/Link/UserLink/UserLink';
import {StorageTitle} from '../../components/Title/StorageTitle/StorageTitle';
import {ConditionOK, ConditionDefect} from '../../constants';
import {FairDate} from '../../services/Utils/Date';

const Event = ({event,products,assets,manifests,users,storages}) => {
    if (!products) return "";
    let asset
    let product


    function returnFileParentLink(parentId){ //waiting for file deleted event to get a parentId
        let parent = products.get(parentId)
        if(parent){
            return <ProductLink id={parent.id} name={parent.name}></ProductLink>
        }
        parent = assets.get(parentId)
        if(parent){
            return <AssetLink id={parent.id} product={products.get(parent.productId)} serialNumber={parent.serialNumber}></AssetLink>
        }
    }

    switch (event.MessageType) {


        //THESE EVENTS SHOULD BE HIDDEN?
        case "manifest.ManifestAssetUpdatedEvent":
            return <span>Updated asset {event.Data.item.assetId === "" ? event.Data.item.description : <AssetLink id={event.Data.item.assetId} />} on manifest <ManifestLink id={event.AggregateID} /></span>

        case "storage.StorageCreatedEvent":
            return <span>Created <StorageLink {...storages.get(event.AggregateID)} /> storage</span>

        case "user.UserHomeChangedEvent":
            return <span>Changed home to  <AddressableTitle id={event.Data.home} /></span>

        case "manifest.ManifestAssetAddedEvent":
            asset = assets.get(event.Data.item.assetId);
            if (!asset) {
                return <span>Added a new item to <ManifestLink id={event.AggregateID} /></span>
            }
            return <span>Added <AssetLink  {...asset} product={products.get(asset.productId)} /> to <ManifestLink id={event.AggregateID} /></span>

        case "manifest.ManifestAssetRemovedEvent":
            return <span>Removed an item from <ManifestLink id={event.AggregateID} /></span>

        case "manifest.ManifestUpdatedEvent":
            return <span>Updated manifest: <ManifestLink id={event.AggregateID} /></span>

        case "user.UserUpdatedEvent":
            return <span>Updated <UserLink {...users.get(event.Data.data.user)} />'s profile</span>

        case "user.UserActivatedEvent":
            return <span><UserLink {...users.get(event.AggregateID)} /> is activated</span>

        case "user.UserCreatedEvent":
            return <span><UserLink {...users.get(event.AggregateID)} /> created user</span>

        case "user.UserRolesSetEvent":
            return <span><UserLink {...users.get(event.AggregateID)} /> got new roles: {event.Data.roles}</span>


        //External
        case "external.ExternalCreatedEvent":
            return <span>Created external <AddressableTitle id={event.AggregateID} /></span>

        case "external.ExternalUpdatedEvent":
            return <span>Updated external <AddressableTitle id={event.AggregateID} /></span>

        case "external.ExternalDeletedEvent":
            return <span>Deleted external <AddressableTitle id={event.AggregateID} /> </span>

        //Ship

        case "ship.ShipCreatedEvent":
            return <span>Created ship <AddressableTitle id={event.AggregateID} /></span>

        case "ship.ShipUpdatedEvent":
            return <span>Updated ship <AddressableTitle id={event.AggregateID} /></span>

        case "ship.ShipDeletedEvent":
            return <span>Deleted ship <AddressableTitle id={event.AggregateID} /> </span>


        //Department
        case "department.DepartmentCreatedEvent":
            return <span>Created department <DepartmentLink id={event.AggregateID} /> with name: {event.Data.name} at <AddressableTitle id={event.Data.parent} /></span>

        case "department.DepartmentDeletedEvent":
            return <span>Deleted department <DepartmentLink id={event.AggregateID} /></span>

        case "department.DepartmentUpdatedEvent":
            return <span>Renamed department <DepartmentLink id={event.AggregateID} /> to {event.Data.name} at </span>

        //Storage
        case "storage.StorageUpdatedEvent":
            return <span>Updated storage <StorageTitle id={event.AggregateID} storages={storages} /></span>

        case "storage.StorageDeletedEvent":
            return <span>Deleted a storage </span>

        //Office
        case "office.OfficeCreatedEvent":
            return <span>Created ship <AddressableTitle id={event.AggregateID} /></span>

        case "office.OfficeUpdatedEvent":
            return <span>Updated office <AddressableTitle id={event.AggregateID} /></span>

        case "office.OfficeDeletedEvent":
            return <span>Deleted office <AddressableTitle id={event.AggregateID} /> </span>

        //FILE
        case "file.FileDeletedEvent":
            return <span>Deleted a file </span>

        case "file.FileUploadedEvent":
            product = products.get(event.Data.parent)
            asset = assets.get(event.Data.parent)
            return <span>Uploaded {event.Data.filename} to {asset && <AssetLink  {...asset} product={products.get(asset.productId)} />} {product && <ProductLink {...product} />}</span>

        //MANIFESTS
        case "manifest.ManifestCreatedEvent":
            return <span>Created manifest: <ManifestLink id={event.AggregateID} /></span>

        case "manifest.ManifestReceivedEvent":
            return <span>Received manifest: <ManifestLink id={event.AggregateID} /> for <StorageLink {...storages.get(manifests.get(event.AggregateID).recipientId)} /></span>

        case "manifest.ManifestSentEvent":
            return <span>Sent manifest: <ManifestLink id={event.AggregateID} /> from <StorageLink {...storages.get(manifests.get(event.AggregateID).senderId)} /> to  <StorageLink {...storages.get(manifests.get(event.AggregateID).recipientId)} /></span>

        //PRODUCTS
        case "product.ProductCreatedEvent":
            product = products.get(event.AggregateID)
            return <span> Created <ProductLink {...product} /></span>

        case "product.ProductLogoUpdatedEvent":
            product = products.get(event.AggregateID)
            return <span> Uploaded new logo for <ProductLink {...product} /></span>

        case "product.ProductDeletedEvent":
            product = products.get(event.AggregateID)
            return <span> Deleted model <ProductLink {...product} /></span>

        case "product.ProductUpdatedEvent":
            product = products.get(event.AggregateID)
            return <span> Updated <ProductLink {...product} /></span>

        //ASSETS
        case "asset.AssetUpdatedEvent":
            asset = assets.get(event.AggregateID);
            return <span> Updated <AssetLink  {...asset} product={products.get(asset.productId)} /></span>

        case "asset.AssetCreatedEvent":
            asset = assets.get(event.AggregateID);
            //Vet ikke hvorfor jeg har en event med en id som ikke er lagret i db.
            if (!asset) return (<span>Created asset {event.AggregateID} but it does not exists?</span>)
            return <span> Created <AssetLink  {...asset} product={products.get(asset.productId)} /></span>

        case "asset.AssetDeletedEvent":
            asset = assets.get(event.AggregateID);
            return <span> Deleted asset <AssetLink  {...asset} product={products.get(asset.productId)} /></span>

        case "asset.AssetShippedEvent":
            asset = assets.get(event.AggregateID);
            return <span>Sent <AssetLink {...asset} product={products.get(asset.productId)} />
                {" "}in manifest: <ManifestLink id={event.Data.ManifestID} /> from {" "}
                <AddressableTitle id={manifests.get(event.Data.ManifestID).senderId} /></span>

        case "asset.AssetReceivedEvent":
            asset = assets.get(event.AggregateID);
            return <span>Received <AssetLink {...asset} product={products.get(asset.productId)} />  in <ManifestLink id={event.Data.ManifestID} /> to <StorageLink {...storages.get(event.Data.StorageID)}/></span>

        case "asset.AssetMovedEvent":
            asset = assets.get(event.AggregateID);
            return <span>Moved <AssetLink {...asset} product={products.get(asset.productId)} />  from  <StorageLink {...storages.get(event.Data.fromStorageId)} /> to <StorageLink {...storages.get(event.Data.toStorageId)} /></span>

        case "asset.AssetCommentDeletedEvent":
            asset = assets.get(event.AggregateID);
            return <span>Deleted theyre comment on <AssetLink {...asset} product={products.get(asset.productId)} /></span>

        case "asset.AssetCommentAddedEvent":
            asset = assets.get(event.AggregateID);
            return <span>Added comment to <AssetLink {...asset} product={products.get(asset.productId)} /> </span>

        case "asset.AssetCalibratedEvent":
            asset = assets.get(event.AggregateID);
            product = products.get(asset.productId);
            return <span>Updated calibration to {FairDate(new Date(event.Data.calibrationDate))} {" "} for {" "}<AssetLink {...asset} product= {product} /></span>

        case "asset.AssetConditionSetEvent":
            asset = assets.get(event.AggregateID);
            return <span>Changed condition to {event.Data.condition === ConditionOK && "OK" }{event.Data.condition === ConditionDefect && "Need Service" } for {" "}<AssetLink {...asset} product={products.get(asset.productId)} /></span>

        default:
            return <div>unknown event type: {event.MessageType}</div>;
    }

};

export default Event;
