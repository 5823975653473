import React,{useState} from 'react';
import withStyles from "react-jss"
import {withRouter} from "react-router-dom";
import {ManifestDraft,ManifestReceived,ManifestSent} from "../../constants";
import {Link} from "react-router-dom";
import {queryObject,queryColumns} from "../../services/Utils/Query";
import SortArrows from '../../components/SortArrows/SortArrows';
import Icon from "../../components/Icon/Icon";
import Input from "../../components/Input/Input";
import {FairDate} from '../../services/Utils/Date';

const styles = {
    root: {
        display: "grid",
        gridGap: "5px",
    },
    newTable: {
        display: "grid",
        gridTemplateColumns: "[col-start] auto [col-end]",
        gridTemplateRows: "[row-start] auto [row-end]",
        gridGap: "0px",
    },
    heading: {
        fontWeight: "bold",
        padding: "10px",
        position: "relative",
    },
    title: {
        gridColumn: "1/9",
        fontWeight: "bold",
        marginBottom: "10px",
        textAlign: "center",
        font: "400 20px sans-serif",
    },
    emptyRow: {
        gridColumn: "1/9",
        fontStyle: "italic",
        padding: "10px",
        textAlign: "center",
    },
    row: {
        padding: "10px",
        position: "relative",
    },
    filterRow: {
        padding: "3px",
        position: "relative",
        gridGap: "1px",
    },
    filterIcon: {
        cursor: "pointer",
        padding: "10px",
        position: "relative",
    },
    rowWrapper: {
        "&:hover div": {
            backgroundColor: "var(--alert-notification-backgroundColor)",
            cursor: "pointer",
        },
        "&:hover span": {
            position: "absolute",
            border: "1px solid black",
            display: "block",
            backgroundColor: "white",
            minWidth: "300px",
            borderRadius: "6px",
            padding: "5px",
        },
        display: "contents",
        position: "relative",
    },
    tip: {
        zIndex: "7",
        position: "relative",
        top: "50px",
        display: "none",
    },
    listEmpty: {
        padding: "10px",
        gridColumn: "1/9",
        justifySelf: "center"
    },
};

const renderState = (state) => {
    switch (state) {
        case ManifestDraft:
            return <Icon title="Draft">short_text</Icon>;
        case ManifestSent:
            return <Icon title="Sent">local_shipping</Icon>;
        case ManifestReceived:
            return <Icon title="Received">done</Icon>;
        default:
            return <Icon>error</Icon>;
    }
};

const renderStateTitle = (state,stateUpdated) => {
    switch (state) {
        case ManifestDraft:
            return "Draft created " + FairDate(stateUpdated.toDate());
        case ManifestSent:
            return "Sent " + FairDate(stateUpdated.toDate());
        case ManifestReceived:
            return "Received " + FairDate(stateUpdated.toDate());
        default:
            return "unknown state";
    }
};

const ManifestList = ({classes,className,manifests,addressables,filter,columnFilter}) => {

    const [sort,setSort] = useState(() => (a,b) => (a.date < b.date) ? 1 : -1);
    const [selectedTag,setSelectedTag] = useState("");

    function handleSort(dynamicSort,tag) {
        setSort(() => dynamicSort)
        setSelectedTag(tag)
    };

    function isSelected(tag) {
        return (selectedTag === tag)
    }

    function prepareManifests(mList) {
        return mList.map(row => {
            row.sender = addressables[row.senderId].name
            row.recipient = addressables[row.recipientId].name
            row.altDate = FairDate(row.date.toDate())
            row.altManifestNumber = "DO-MF-"+row.manifestNumber
            return row
        })
    }

    const [filterDate,setFilterDate] = useState("");
    const [filterManifestNumber,setFilterManifestNumber] = useState("");
    const [filterName,setFilterName] = useState("");
    const [filterProject,setFilterProject] = useState("");
    const [filterReference,setFilterReference] = useState("");
    const [filterSender,setFilterSender] = useState("");
    const [filterRecipient,setFilterRecipient] = useState("");
    const [filterState,setFilterState] = useState(4);
    const [filterStateIcon,setFilterStateIcon] = useState(<Icon onClick={() => handleFilterStateChange(4)} title="Click to filter">all_inclusive</Icon>);
    
    const handleFilterDateChange = (e) => {
        setFilterDate(e.target.value)
    };
    const handleFilterManifestNumberChange = (e) => {
        setFilterManifestNumber(e.target.value)
    };
    const handleFilterNameChange = (e) => {
        setFilterName(e.target.value)
    };
    const handleFilterProjectChange = (e) => {
        setFilterProject(e.target.value)
    };
    const handleFilterReferenceChange = (e) => {
        setFilterReference(e.target.value)
    };
    const handleFilterSenderChange = (e) => {
        setFilterSender(e.target.value)
    };
    const handleFilterRecipientChange = (e) => {
        setFilterRecipient(e.target.value)
    };

    function handleFilterStateChange (newState) {
        if (newState === 0) {
            setFilterState(4);
            setFilterStateIcon(<Icon onClick={() => handleFilterStateChange(4)} title="Click to filter">all_inclusive</Icon>);
        }
        else if (newState === 1) {
            setFilterState(0);
            setFilterStateIcon(<Icon onClick={() => handleFilterStateChange(0)} title="Showing only drafts">short_text</Icon>);
        }
        else if (newState === 2) {
            setFilterState(1);
            setFilterStateIcon(<Icon onClick={() => handleFilterStateChange(1)} title="Showing only sent">local_shipping</Icon>);
        }
        else if (newState === 4) {
            setFilterState(2);
            setFilterStateIcon(<Icon onClick={() => handleFilterStateChange(2)} title="Showing only recieved">done</Icon>);
        }
    };

    var filterList = {};
    if (filterDate !== "") {
        filterList["altDate"] = filterDate
    }
    if (filterManifestNumber !== "") {
        filterList["altManifestNumber"] = filterManifestNumber
    }
    if (filterName !== "") {
        filterList["name"] = filterName
    }
    if (filterProject !== "") {
        filterList["project"] = filterProject
    }
    if (filterReference !== "") {
        filterList["reference"] = filterReference
    }
    if (filterSender !== "") {
        filterList["sender"] = filterSender
    }
    if (filterRecipient !== "") {
        filterList["recipient"] = filterRecipient
    }
    if (filterState !== 4) {
        filterList["state"] = filterState    
    }
      
    manifests = queryObject(prepareManifests(manifests),filter).sort(sort)
    if(columnFilter){
        manifests = queryColumns(manifests,filterList)
    }
    
    return (
        <div className={[classes.root,className].filter(v => v).join(" ")}>
            <div className={classes.newTable}>
                <div className={classes.title}>Manifests</div>
                <div className={classes.heading}>Date<SortArrows handleSort={handleSort} tag="date" selected={isSelected("date")} /></div>
                <div className={classes.heading}>DO-MF NO<SortArrows handleSort={handleSort} tag="name" selected={isSelected("name")} /></div>
                <div className={classes.heading}>Name<SortArrows handleSort={handleSort} tag="name" selected={isSelected("name")} /></div>
                <div className={classes.heading}>Project<SortArrows handleSort={handleSort} tag="project" selected={isSelected("project")} /></div>
                <div className={classes.heading}>Reference<SortArrows handleSort={handleSort} tag="reference" selected={isSelected("reference")} /></div>
                <div className={classes.heading}>Sender<SortArrows handleSort={handleSort} tag="sender" selected={isSelected("sender")} /></div>
                <div className={classes.heading}>Recipient<SortArrows handleSort={handleSort} tag="recipient" selected={isSelected("recipient")} /></div>
                <div className={classes.heading}>Status</div>
                {columnFilter &&
                    <>
                    <div className={classes.filterRow}><Input className={classes.search} placeholder={"Filter"} onChange={handleFilterDateChange} value={filterDate} size="4"/></div>
                    <div className={classes.filterRow}><Input className={classes.search} placeholder={"Filter"} onChange={handleFilterManifestNumberChange} value={filterManifestNumber} size="4"/></div>
                    <div className={classes.filterRow}><Input className={classes.search} placeholder={"Filter"} onChange={handleFilterNameChange} value={filterName} /></div>
                    <div className={classes.filterRow}><Input className={classes.search} placeholder={"Filter"} onChange={handleFilterProjectChange} value={filterProject} /></div>
                    <div className={classes.filterRow}><Input className={classes.search} placeholder={"Filter"} onChange={handleFilterReferenceChange} value={filterReference} /></div>
                    <div className={classes.filterRow}><Input className={classes.search} placeholder={"Filter"} onChange={handleFilterSenderChange} value={filterSender} /></div>
                    <div className={classes.filterRow}><Input className={classes.search} placeholder={"Filter"} onChange={handleFilterRecipientChange} value={filterRecipient} /></div>
                    <div className={classes.filterIcon}>{filterStateIcon}</div>
                    </>
                }
                {manifests.slice(0,50).map((row) => (
                    <Link to={"manifests/" + row.id} key={row.id} className={classes.rowWrapper} >
                        <div className={classes.row}>{FairDate(row.date.toDate())}</div>
                        <div className={classes.row}>{row.manifestNumber}</div>
                        <div className={classes.row}>{row.name}</div>
                        <div className={classes.row}>{row.project}
                            <span className={classes.tip}>{renderStateTitle(row.state,row.stateUpdated)}<br />
                                {row.items && Object.values(row.items).map((item) => (
                                    <em key={item.id}>{item.description + " : " + item.serialNumber}<br /></em>
                                ))
                                }
                            </span>
                        </div>
                        <div className={classes.row}>{row.reference}</div>
                        <div className={classes.row}>{row.sender}</div>
                        <div className={classes.row}>{row.recipient}</div>
                        <div className={classes.row}>{renderState(row.state)}</div>
                    </Link>
                ))
                }{manifests.length > 50 &&
                    <div className={classes.listEmpty}>Showing 50 manifests of {manifests.length}, please use filter to limit result</div>
                }{manifests.length === 0 &&
                    <div className={classes.listEmpty}>No manifests found</div>
                }
            </div>
        </div>
    );
};

export default withRouter(withStyles(styles)(ManifestList));