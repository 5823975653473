import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './pages/App.js';
import * as serviceWorker from './serviceWorker';
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = require('./firebase-config.json');

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

if (process.env.REACT_APP_FIRESTORE === "emulator") {
    firebase.firestore().settings({
        host: "localhost:9999",
        ssl: false,
        cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
    });
}else {
    firebase.firestore().settings({
        cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
    });
    firebase.firestore().enablePersistence({synchronizeTabs:true});
}


ReactDOM.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>,
document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
