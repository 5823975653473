import React from "react"
import withStyles from 'react-jss'

const styles = {
    root: {
        width: "100%",
        color: "var(--first-text-color)",
    },
};

const Table = ({classes,children})=>{
    return (
        <table className={classes.root}>
            <tbody>
                {children}
            </tbody>
        </table>
    )
};

export default withStyles(styles)(Table);