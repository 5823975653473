import React, {useEffect, useRef, useState} from "react";
import Scanner from "zbar.wasm";
import UserMedia from "../UserMedia/UserMedia";

const QRScanner = ({fps=2, onScan}) => {
    const canvas = useRef(document.createElement("canvas"));
    const video = useRef(null);
    const [scanner, setScanner] = useState(null);

    useEffect(()=>{
        Scanner({locateFile: file => '/' + file}).then(setScanner);
    },[]);

    useEffect(() => {

        const c = canvas.current;
        const v = video.current;

        if(v == null || c == null){
            return;
        }

        const ctx = c.getContext('2d');

        const onLoadedMetadata = ()=>{
            c.width = v.videoWidth;
            c.height = v.videoHeight;
        };
        v.addEventListener('loadedmetadata', onLoadedMetadata);

        const drawHandler = setInterval(()=>{
            ctx.drawImage(v, 0, 0);
        },1000/fps);

        return ()=>{
            v.removeEventListener('loadedmetadata', onLoadedMetadata);
            clearInterval(drawHandler)
        }
    }, [video,canvas,fps]);

    const getImage = ()=>{
        const c = canvas.current;
        const ctx = c.getContext('2d');
        return [
            ctx.getImageData(0, 0, c.width, c.height).data,
            c.width,
            c.height,
        ];
    };

    useEffect(()=>{
        if(!scanner){
            return;
        }
        const handler = setInterval(()=>{
            const result = scanner.scanQrcode(...getImage());
            if(result.length === 0){
                return;
            }
            onScan && onScan(result[0])
        },1000/fps);
        return ()=>clearInterval(handler)
    },[scanner,fps,onScan]);

    return  <UserMedia ref={video}/>

};

export default QRScanner;