import React from "react";
import {ManifestsResource} from "../../../services/Firestore/Resources";

export const ManifestTitle = ({id,name,manifestNumber})=>{
    if(!name || !manifestNumber){
        return <ManifestsResource>{manifests =>
            <ManifestTitle {...manifests.get(id)}/>
        }</ManifestsResource>
    }

    return `[${manifestNumber}] ${name}`
};

