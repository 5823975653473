import React,{useState} from 'react';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import AddressableSelect from "../Select/AddressableSelect";
import Form from "../../components/Form/Form";
import Textarea from '../../components/Textarea/Textarea';
import withStyles from 'react-jss'
import Icon from "../../components/Icon/Icon";

const styles = {
    split: {
        display: "grid",
        gridTemplateColumns: "auto auto auto auto",
    },
};

const convertArrayToObject = (array, key) => {//DUPLICATE from assetAddForm
    const initialValue = {};
    return array.reduce((obj, item) => {
      return {
        ...obj,
        [item[key]]: item,
      };
    }, initialValue);
  };

const ManifestEditForm = ({classes, onUpdate, manifest,ships,offices,departments,externals}) => {

    let parentList= offices.concat(ships,departments,externals)
    parentList = convertArrayToObject(parentList,'id')
   
    const {id} = manifest;
    const [name, setName] = useState(manifest && manifest.name);
    const [project, setProject] = useState(manifest && manifest.project);
    const [reference, setReference] = useState(manifest && manifest.reference);
    const [recipientAddress, setRecipientAddress] = useState(manifest && manifest.recipientAddress);
    const [senderAddress, setSenderAddress] = useState(manifest && manifest.senderAddress);
    const [recipientId, setRecipientId] = useState(manifest && manifest.recipientId);
    const [senderId, setSenderId] = useState(manifest && manifest.senderId);
    const [additionalNotes, setAdditionalNotes] = useState(manifest && manifest.additionalNotes);
    const [currency, setCurrency] = useState(manifest && manifest.currency);

    const handleUpdate = function (e) {
        e.preventDefault();
        onUpdate({
            id,
            name,
            project,
            reference,
            recipientAddress,
            senderAddress,
            recipientId,
            senderId,
            additionalNotes,
            currency,
        })
    };
    
    const handleSelectRecipient = ({value,label})=>{
        setRecipientId(value.id);
        setRecipientAddress(getAddress(value.id));
    };

    const handleSelectSender = ({value,label})=>{
        setSenderId(value.id);
        setSenderAddress(getAddress(value.id))
     };

    const handleSwitchRecipientAndSender = ()=>{
        const [sId,rId] = [senderId,recipientId]
        setRecipientId(sId);
        setRecipientAddress(getAddress(sId));
        setSenderId(rId);
        setSenderAddress(getAddress(rId))
    };

     function getAddress(id) {
        let place = parentList[id]
        if (place.parent) {
            return (parentList[place.parent].address)
        } else {
            return (place.address)
        }
    }

    return (
        <Form className={classes.split} onSubmit={handleUpdate}>
            <Input style={{gridColumn: "1 / 3"}} title="Manifest title" required value={name} onChange={e => setName(e.target.value)}/>
            <Input style={{gridColumn: "3 / 5"}} title="Project" value={project} onChange={e => setProject(e.target.value)}/>
            <Input style={{gridColumn: "1 / 3"}} title="Our reference" value={reference} onChange={e => setReference(e.target.value)}/>
            <Input style={{gridColumn: "3 / 5"}} title="Currency" required minLength="3" maxLength="3"  value={currency} onChange={e => setCurrency(e.target.value)}/>
            <AddressableSelect title="Recipient" required value={recipientId} onChange={handleSelectRecipient}/>
            <Button
                type="button"
                onClick={handleSwitchRecipientAndSender}
                circle
                title={"Switch recipient and sender"}
                style={{gridColumn: "2 / 4", justifySelf:"center", alignSelf: "center"}}
            >
                <Icon>swap_horiz</Icon>
            </Button>
            <AddressableSelect title="Sender" required value={senderId} onChange={handleSelectSender}/>
            <Textarea  required style={{gridColumn: "1 / 5"}} maxLength="200" rows="5" cols="15" title="Additional notes" value={additionalNotes} onChange={e => setAdditionalNotes(e.target.value)}/>
            <Button type="submit" style={{gridColumn: "1 / 5"}}>Save</Button>
        </Form>
    );
};

export default withStyles(styles)(ManifestEditForm)