import React,{useEffect} from 'react';
import {create} from 'jss'
import preset from 'jss-preset-default'
import {useDocumentTitle} from "../../services/Utils/useDocumentTitle";
import {FairDate} from "../../services/Utils/Date";

const styles = {
    gridContainer: {
        boxSizing: "border-box",
        border: "1px solid black",
        minHeight: "100vh",
        width: "100vw",
        display: "grid",
        gridTemplateColumns: "auto",
        gridTemplateRows: "min-content auto min-content",
        gridTemplateAreas: '"header" "body" "footer"',
        "& > * > *":{
            padding: 5,
        }
    },
    header: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr 1fr",
        gridTemplateRows: "auto auto auto",
        gridTemplateAreas:
            '"title title image image" "companyDetails companyDetails image image" "recipient additionalNotes sender details"',
        gridArea: "header",
    },
    title: {
        gridArea: "title",
        fontSize: "27px",
        textTransform: "uppercase",
        fontWeight: "bold",
    },
    image: {
        gridArea: "image",
        justifySelf: "right",
        alignSelf: "center",
        "& img": {
            height: "40px",
        },
    },
    recipient: {
        gridArea: "recipient",
        border: "1px solid black",
        borderLeft: 0,
        borderRight: 0,
    },
    additionalNotes: {
        gridArea: "additionalNotes",
        border: "1px solid black",
        borderRight: 0,
    },
    sender: {
        gridArea: "sender",
        border: "1px solid black",
        borderRight: 0,
    },
    details: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridAutoRows: "auto",
        gridTemplateAreas: '". ."',
        gridArea: "details",
        "& > *":{
            minHeight: 15,
            padding: 2,
            boxSizing: "content-box"
        },
        "& > *:not(:nth-last-child(-n+2))":{
            borderBottom: "1px solid lightgray"
        },
        "& > *:nth-child(2n+1)":{
            fontWeight: "bold"
        },
        "& > *:nth-child(2n)":{
            textAlign: "right"
        },
        alignItems: "center",
        border: "1px solid black",
        borderRight: 0,
        whiteSpace: "nowrap"
    },
    preformatted: {
        whiteSpace: "pre-line",
    },
    companyDetails: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridTemplateRows: "1fr",
        gridTemplateAreas: '". ."',
        gridArea: "companyDetails",
    },
    body: {
        display: "grid",
        gridAutoRows: "min-content",
        gridTemplateColumns: "repeat(3,min-content) auto auto auto min-content repeat(3,auto)",
        gridArea: "body",
        "& > *:not(:first-child)": {
            borderLeft: "1px solid lightGray",
            borderTop: "1px solid lightGray"
        }
    },
    footer: {
        display: "grid",
        gridTemplateColumns: "1fr 0.5fr 1fr 0.5fr",
        gridTemplateRows: "1fr",
        gridTemplateAreas: '". . . ."',
        gridArea: "footer",
        alignItems: "end",
        "& > * > *": {
            padding: 3,
        },
        "& > * > *:last-child": {
            borderBottom: "1px solid black"
        }
    },
    itemHeader: {
        fontWeight: "bold",
        borderTop: "0 !important"
    },
    totalHeader: {
        fontWeight: "bold",
        textAlign: "right",
    },
    totalValue: {
        fontWeight: "bold",
    },
    '@media screen': {
      '@global': {
          body: {
              overflow: "hidden",
          }
      }
    },
    '@global': {
        "html, body": {
            padding: 0,
            margin: 0,
            fontFamily: "sans-serif",
            fontSize:"12px"
        },
        "h1,h2": {
            marginTop: 0,
            marginBottom: 4,
        }
    }
};

const ManifestItemPrint = ({number,item})=>{
    return <>
        <div>{number}</div>
        <div>{item.quantity}</div>
        <div>{item.boxNo}</div>
        <div>{item.description}</div>
        <div>{item.serialNumber}</div>
        <div>{item.owner}</div>
        <div>{item.rental ? 'YES' : 'NO'}</div>
        <div>{item.weight}</div>
        <div>{item.dimensions}</div>
        <div>{item.value}</div>
    </>
};

export const ManifestPrintPage = ({window, manifest, proforma, logo}) => {
    const jss = create(preset());
    const sheet = jss.createStyleSheet(styles);
    const {classes} = sheet;
    useDocumentTitle("DO-MF-"+manifest.manifestNumber+"-"+manifest.name, {window});

    useEffect(()=>{
        window.setTimeout(window.print,1000);
    },[window]);

    const totalValue = ()=>{
        return Object.values(manifest.items)
            .reduce((tot, item) => tot + item.value,0);
    };

    const totalWeight = ()=>{
        return Object.values(manifest.items)
            .reduce((tot, item) => tot + item.weight,0);
    };

    const totalBoxes = ()=>{
        return Object.values(manifest.items)
            .map(item => item.boxNo)
            .filter((value, index, self) => self.indexOf(value) === index)
            .length
    };

    return <>
        <style dangerouslySetInnerHTML={{__html: sheet.toString()}} type={"text/css"}/>
        <div className={classes.gridContainer}>
            <div className={classes.header}>
                <h1 className={classes.title}>{proforma ? 'Proforma invoice' : 'Shipping manifest'}</h1>
                <div className={classes.image}><img alt={"logo"} src={logo}/></div>
                <div className={classes.recipient}>
                    <h2>Recipient</h2>
                    <div className={classes.preformatted}>{
`${manifest.recipient.name}
${manifest.recipientAddress}`
                    }</div>
                </div>
                <div className={classes.additionalNotes}>
                    <h2>Additional notes</h2>
                    <div className={classes.preformatted}>{manifest.additionalNotes}</div>
                </div>
                <div className={classes.sender}>
                    <h2>Sender</h2>
                    <div className={classes.preformatted}>{
`${manifest.sender.name}
${manifest.senderAddress}`
                    }</div>
                </div>
                <div className={classes.details}>
                    <span className={classes.detailsHeader}>Manifest number:</span>
                    <span>DO-MF-{manifest.manifestNumber}</span>
                    <span className={classes.detailsHeader}>Date:</span>
                    <span>{FairDate(manifest.date.toDate())}</span>
                    <span className={classes.detailsHeader}>Project number:</span>
                    <span>{manifest.project}</span>
                    <span className={classes.detailsHeader}>Our reference:</span>
                    <span>{manifest.reference}</span>
                </div>
                <div className={[classes.companyDetails, classes.preformatted].join(" ")}>
                    <div>
                        {
`DEEPOCEAN AS
Karmsundgaten 74
Postboks 2144
Postterminalen
NO 5504 Haugesund`
                        }
                    </div>
                    <div>
                        {
`Tel: +47 52700400
Fax: +47 52700401
Email: post@deepoceangroup.com
Enterprise NO 980722805
https://www.deeepoceangroup.com`
                        }
                    </div>
                </div>
            </div>
            <div className={classes.body}>
                <div className={classes.itemHeader}>Item</div>
                <div className={classes.itemHeader}>Quantity</div>
                <div className={classes.itemHeader}>BoxNo</div>
                <div className={classes.itemHeader}>Description</div>
                <div className={classes.itemHeader}>Serial/Tag no.</div>
                <div className={classes.itemHeader}>Owner</div>
                <div className={classes.itemHeader}>Rental</div>
                <div className={classes.itemHeader}>Gross weight</div>
                <div className={classes.itemHeader}>Dimensions</div>
                <div className={classes.itemHeader}>Value {manifest.currency}</div>
                {manifest.items.map((i,n)=>
                    <ManifestItemPrint key={i.id} item={i} number={n+1}/>
                )}
                <div className={classes.totalHeader} style={{gridColumn: "1 / 3"}}>Total boxes:</div>
                <div className={classes.totalValue}>{totalBoxes()}</div>
                <div className={classes.totalHeader} style={{gridColumn: "4 / 8"}}>Total weight:</div>
                <div className={classes.totalValue}>{totalWeight()} kg</div>
                <div className={classes.totalHeader}>Total value:</div>
                <div className={classes.totalValue}>{totalValue()} {manifest.currency}</div>
            </div>
            <div className={classes.footer}>
                <div>
                    <div>Prepared on behalf of DeepOcean</div>
                    <div>Name:</div>
                    <div>Sign:</div>
                </div>
                <div>
                    <div>Date:</div>
                </div>
                <div>
                    <div>Received/Accepted on behalf of recipient </div>
                    <div>Name:</div>
                    <div>Sign:</div>
                </div>
                <div>
                    <div>Date:</div>
                </div>
            </div>
        </div>
    </>
};
