import React,{useEffect,useState,useRef} from "react"
import Option from "../Select/Option/Option";
import Select from "../Select/Select";
import {useLocalStorage} from "../../services/LocalStorage/useLocalStorage";
import withStyles from "react-jss";

const styles = {
  root:{
      "& > video":{
          width: "100%"
      }
  },
  videoInput: {
      position: "absolute",
      zIndex: 1,
      width: "100%",
      backgroundColor: "rgba(255,255,255,0.5)"
  }
};

const UserMedia = React.forwardRef(({classes,kind = "videoinput"},ref)=>{
    const video = ref || useRef(null);
    const [devices, setDevices] = useState([]);
    const [selectedDeviceId, setSelectedDeviceId] = useLocalStorage("UserMedia.selectedDevice",null);
    const [selectedDevice, setSelectedDevice] = useState(null);
    const [mediaStream, setMediaStream] = useState(null);

    useEffect(() => {
        const mediaDevices = navigator.mediaDevices;
        if (!(mediaDevices && mediaDevices.getUserMedia)){
            return;
        }
        mediaDevices.enumerateDevices().then(devices => devices.filter(d => {
            if (kind){
                return d.kind === kind
            }
            return true
        })).then(setDevices);
    }, [kind]);

    useEffect(()=>{
        if (devices.length === 0) {
            return;
        }
        let device = devices.find(d=>d.deviceId === selectedDeviceId);
        if (!device){
            device = devices[0];
        }
        setSelectedDevice(device)
    },[devices,selectedDeviceId]);

    useEffect(()=>{
        if(!selectedDevice){
            return;
        }

        switch (selectedDevice.kind) {
            case "videoinput":
                navigator.mediaDevices.getUserMedia({
                       video: {
                           advanced: [{
                                   deviceId: selectedDevice.deviceId
                           }]
                       }
                }).then(setMediaStream);
                break;
            case "audioinput":
                //TODO implement audioinput?
                break;
            default:
                throw Error("unknown media kind")
        }
    },[selectedDevice]);


    useEffect(()=>{
        if(!mediaStream || !video.current){
            return;
        }
        const v = video.current;

        v.srcObject = mediaStream;
        v.play();
        return ()=>{
            const tracks = mediaStream.getTracks();
            tracks.forEach(track => track.stop());
            v.srcObject = null;
        }
    },[video,selectedDevice,mediaStream]);

    const handleChangeDevice = ({value,/*label*/})=>{
        setSelectedDeviceId(value)
    };

    return <div className={classes.root}>
        {devices.length === 0 && <p>No media devices available...</p>}
        {devices.length > 1 && <div className={classes.videoInput}>
            <Select placeholder={"Tap to change video input"} value={selectedDevice} onChange={handleChangeDevice}>
                {devices.map((d,i)=><Option key={d.deviceId} value={d.deviceId}>{d.label}</Option>)}
            </Select>
        </div>}
        <video ref={video}/>
    </div>;

});

export default withStyles(styles)(UserMedia)